import React from 'react';
import './SkillsSection.css'; // Import your CSS file

const skillsData = [
    { id: 1, name: 'Modern C++', rating: 85, tags: ['Professional', 'Personal'] },
    { id: 2, name: 'Computer Architecture', rating: 95, tags: ['Academic', 'Professional'] },
    { id: 4, name: 'Digital Electronics (FPGA & ASICs)', rating: 82, tags: ['Academic', 'Professional'] },
    { id: 5, name: 'System Verilog', rating: 70, tags: ['Academic', 'Professional'] },
    { id: 6, name: 'Golang', rating: 60, tags: ['Professional'] },
    { id: 8, name: 'Computer Networks', rating: 80, tags: ['Professional'] },
    { id: 9, name: 'Machine Learning & Signal Processing', rating: 60, tags: ['Academic', 'Professional', 'Personal'] },
    { id: 3, name: 'Python', rating: 80, tags: ['Professional', 'Personal'] },
];

const renderProgressBar = (rating) => {
    const clampedRating = Math.max(0, Math.min(100, rating));
    return (
        <div className="progress-bar-container">
            <div
                className="progress-bar"
                style={{ width: `${clampedRating}%` }}
            ></div>
        </div>
    );
};

const SkillsSection = () => {
    return (
        <div id="skills">
            <div className="topTitleBox">
                <div className="About-title-box">
                    <h1 id="About" className="red-line">Skills</h1>
                </div>
            </div>
            <div className="skills-list">
                {skillsData.map(skill => (
                    <div key={skill.id} className="skill-card">
                        <h3>{skill.name}</h3>
                        <div className="skill-rating">
                            {renderProgressBar(skill.rating)}
                        </div>
                        <div className="skill-tags">
                            {skill.tags.map((tag, idx) => (
                                <span key={idx} className="skill-tag">{tag}</span>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SkillsSection;
