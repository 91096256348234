// src/layouts/InternshipCard.js
import React from 'react';
import './InternshipCard.css';

const InternshipCard = ({ companyName, description, role, dateRange }) => {
    return (
        <div className="internship-card">
            <div className="logo-wrapper">
                {/* Removed logo for a cleaner look */}
                <h2 className="company-name">{companyName}</h2>
                <div className="role-badge">{role}</div>
            </div>
            <div className="date-range">{dateRange}</div>
            <div className="company-description">
                {description}
            </div>
        </div>
    );
};

export default InternshipCard;

