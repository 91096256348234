import React from 'react';
import { header } from '../../profile'; // Adjust path as needed
import ScrollArrow from '../layouts/ScrollArrow.js'
// import './Header.css'; // Import the CSS file for styling

const Header = () => {
    return (
        <div className="header-container">
            <div className="Header">
                <h1>{`I'm ${header.name}`}</h1>
                <p className="line-1 anim-typewriter">and this is my portfolio.</p>
            </div>
            <ScrollArrow />
        </div>
    );
};

export default Header;













































