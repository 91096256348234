import React, { Component } from "react";
import "./ProjectCard.css"
import ReactDOM from "react-dom";

class ProjectCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
        };
    }

    toggleExpand = () => {
        this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
        document.body.style.overflow = !this.state.isExpanded ? 'hidden' : 'auto';
    };

    handleClose = (e) => {
        if (e.target.className.includes("overlay")) {
            this.toggleExpand();
        }
    };

    render() {
        const { title, description, imageUrl, projectType } = this.props;
        const { isExpanded } = this.state;

        const badgeClass = projectType === "university" ? "badge-university" : "badge-personal";

        return (
            <>
                <div className="card-container" onClick={this.toggleExpand}>
                    <div className="card">
                        <div className="image-wrapper">
                            <img src={imageUrl} alt={title} className="card-image" />
                        </div>
                        <div className="card-content">
                            <h3 className="card-title">{title}</h3>
                            <span className={`badge ${badgeClass}`}>{projectType}</span>
                            <p className="card-description truncated">{description}</p>
                            <div className="view-more">
                                View More
                            </div>
                        </div>
                    </div>
                </div>
                {isExpanded && ReactDOM.createPortal(
                    <div className="overlay" onClick={this.handleClose}>
                        <div className="expanded-card">
                            <div className="image-wrapper">
                                <img src={imageUrl} alt={title} className="card-image" />
                            </div>
                            <div className="card-content">
                                <h3 className="card-title">{title}</h3>
                                  <span className="badge">{projectType === "university" ? "" : ""}</span>
                                <p className="card-description">{description}</p>
                                <div className="close-icon" onClick={this.toggleExpand}>
                                    &times;
                                </div>
                            </div>
                        </div>
                    </div>,
                    document.body // render the overlay outside of the carousel container
                )}
            </>
        );
    }
}

export default ProjectCard;

