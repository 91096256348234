// src/sections/InternshipSection.js
import React from 'react';
import InternshipCard from '../layouts/InternshipCard';
import './InternshipSection.css';

const internships = [
    {
        companyName: "Porsche Motorsport",
        description: "Completed a 6-month placement at Porsche Motorsports in Weissach, Germany, where I developed and maintained high-throughput, low-latency data pipelines and applications used in production on a race-weekend. I also worked closely with engineers from other departments to implement signal processing algorithms and provide intelligent insights.",
        role: "Data Science & Engineering",
        dateRange: "April 2024 - September 2024"
    },
    {
        companyName: "ARM",
        description: "In 2023, I worked in the ARM GPU Modelling department to assist developing a model of the new-generation GPU. Throughout my internship I was exposed to GPU microarchitecture along with a professional C++ and System Verilog codebase. I saw my own components through the development, testing and integration phases of the development cycle.",
        role: "GPU Modelling Engineer",
        dateRange: "July 2023 - September 2023"
    },
    {
        companyName: "Protean Electric",
        description: "In July 2022, I spent one month developing a driver for communication protocol providing a high-speed serial link in C with automotive-grade hardware, along with my own PCB and associated testing suites. This was also my first introduction to the development, prototyping and verification process of both hardware and software in a safety-critical environment adhering to ISO standards.",
        role: "Hardware & Software Engineer",
        dateRange: "July 2022 (1 Month)"
    },
    {
        companyName: "Imperial College Data Science Society",
        description: "As the Head of Projects and Competitions, I led a team of students to enter into the NumerAI Data Science competition throughout the academic year. The students possessed a range of skills and backgrounds, from first year undergraduates to Master's students with years of industry experience, and I ensured that every member was involved and had the opportunity to contribute meaningful work.",
        role: "Head of Projects and Competitions",
        dateRange: "October 2023 - April 2024"
    },
    {
        companyName: "Bentley Motors",
        description: "During my (school) work experience at Bentley Motors in Crewe, my main task was to develop automated parking sensors, which made braking decision based on humidity readings using a low-processing power Arduino, forcing my code to be extremely efficient.",
        role: "Electronics & QC Intern",
        dateRange: "July 2019"
    },
    {
        companyName: "Formula Student AI",
        description: "I was one of four first year students in Imperial College London who was selected to be part of the team to represent the university in the Formula Student Artificial Intelligence Competition. As a member of the vision team, I had to ensure that clean useable data from lidar sensors and cameras was fed to the path-planning team to drive the car around a track. My main job was to gather image-data from simulations that could then be used in the main model.",
        role: "Computer Vision Engineer",
        dateRange: "November 2021 - June 2022"
    },
    // Add more internships as needed
];

const InternshipSection = () => {
    return (
        <div id="experience" data-aos="zoom-in-up" className="internship-section">
          <div className="topTitleBox">
          <div className="About-title-box">
            <h1 id="About" className="red-line">Experience</h1>
          </div>
          </div>
            <div className="internship-grid">
                {internships.map((internship, index) => (
                    <InternshipCard
                        key={index}
                        companyName={internship.companyName}
                        description={internship.description}
                        role={internship.role}
                        dateRange={internship.dateRange}
                    />
                ))}
            </div>
        </div>
    );
};

export default InternshipSection;

