import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ProjectCard from '../layouts/ProjectCard';
import './ProjectCarousel.css';

const ProjCarousel = (props) => {
    const projects = [
        {
            title: "Safis",
            description: "I am currently writing my own realtime HD Video Streaming platform to be used with my First Person racing drones. Written in Modern C++, the goal is to provide a low-latency high quality video stream using affordable, off-the-shelf hardware. Currently I am developing the core streaming component similar to an IP Camera and the next step is to modify drivers for the Realtek RTL8812au WiFi modules (written in C) to be used as a programmable radio for communication over the air.",
            imageUrl: "projects/drone_videostream_project.png",
            projectType: "Personal"
        },
        {
            title: "CORDIC",
            description: "I implemented and verified IP to accelerate the calculation of cosine. The target device was the Altera Cyclone V FPGA, and the IP was then integrated into a custom instruction for the NIOS-II softprocessor. First I developed a bit-accurate model in C++ to explore the resource/latency design space of the fixed-point CORDIC algorithm using Monte Carlo simulations. I interpretted these results and designed a system architecture that met the accuracy requirements with optimal latency and resource usage. The next step was to implement this architecture in System Verilog, verify the IP with my model in C++ and then integrate it into the NIOS-II soft-cpu. I ensured that all timing constraints were met, and the unit was then tested on the Cyclone V FPGA.",
            imageUrl: "projects/keyboard_firmware.jpg",
            projectType: "University"
        },
        {
            title: "StackSynth",
            description: "In a group of four we wrote the firmware for stackable Synthesiser written in Modern C++ for a STM32 Nucleo using an ARM Cortex M4 MPU. I directly worked on the implementation of the core firmware, developing the system architecture and providing the other team-mates with memory- and thread-safe abstractions, which were then used to implement features and overall system functionality. I also optimised the critical loop, and worked with my team-mates to develop the driver and statemachines used for communication between boards over the CAN bus. This also taught me about features such as DMA (direct memory access) and gave me the opportunity to practice concurrency and memory-safety in a practical setting.",
            imageUrl: "projects/stack-synth.png",
            projectType: "University"
        },
        {
            title: "HW Acceleration",
            description: "I profiled and accelerated the evaluation of a mathematical expression to optimise the calculation latency while using the least resources possible. Given the constraints I decided to develop my own IP blocks and integrated them with existing protocols and IP to map the entire mathematical function to hardware. I also implemented both a folded and pipelined architecture, which taught me about timing constraints and synchronisation techniques between different components. After implementing the component in system in System Verilog, I verified the IP against a bit-accurate model I wrote in MATLAB and benchmarked the final system using latency and resource utilisation as key metrics. Keys topics I covered and skills I developed were Direct Memory Access, my knowledge of System Verilog and writing drivers for hardware components in C.",
            imageUrl: "projects/keyboard_firmware.jpg",
            projectType: "University"
        },
        {
            title: "RISC-V CPU",
            description: "I implemented a pipelined RISC-V CPU compatible with the RV32I instruction set in System Verilog and wrote the testbench with the Verilator library in C++. This hands-on approach to computer architecture taught me practical skills, like writing testbenches and how features and optimisations within the field of Computer Architecture are implemented in hardware. I extended this project further by implementing my own cache controller and eviction scheme, and integrating this into my CPU.",
            imageUrl: "/projects/riscv_cpu.png",
            projectType: "University"
        },
        {
            title: "C90 Compiler",
            description: "Together with another student, we developed a compiler from C90 into RISC-V Assembly. This hands-on project showed me the inner workings of a Compiler along with providing experience working in Assembly. This greatly improved my understanding of how a computer program is run on a general-purpose processor.",
            imageUrl: "/projects/riscv_cpu.png",
            projectType: "University"
        },
        {
            title: "AutoEye",
            description: "AutoEye is a low-latency, low-power IoT device built to combat vehicle theft in the world's major cities. The device uses a GPS, accelerometer, temperature/humidity sensors and an LTE hat for full monitoring and theft detection of your vehicle before it is too late. The intuitive UI, high-throughput backend (Go, SQL, MQTT) and custom device drivers work together to provide the user with a plug-and-play experience and ensure both reliable operation and scalability. I was in charge of connectivity over LTE, secure communication over MQTT and HTTP using TLS encryption, developing the backend to 'consume and serve' data, and overall system design.",
            imageUrl: "/projects/autoeye_logo.png",
            projectType: "University"
        },
        {
            title: "Road Grading",
            description: "Worked with students from IIT to develop an algorithm to analyse and grade road damage from video streams. My role was within the ML team, where I cleaned and preprocessed the training data along with designing the core algorithm based on Mask R-CNN.",
            imageUrl: "projects/compvis_logo.png",
            projectType: "Personal"
        },
        {
            title: "Racing Drones",
            description: "I have been building and flying racing drones since I was 14 years old. Throughout my degree I have progressively learnt about each subsystem and how they operate. This includes the PID based control systems (where I wrote a 2DoF stabilisation algorithm for a drone as part of my Control Systems module), wireless communications, circuit design and how to write firmware and drivers for each component. In 2021 I modified and compiled a variant of iNav for my own long-distance drone project to provide compatibility for my unsupported hardware, and most recently started to develop my own HD video system that I plan to use on my own project.",
            imageUrl: "projects/drone_image.png",
            projectType: "Personal"
        },
        
        // Add more projects as needed
    ];

    const responsive = {
        extraSuperLargeDesktop: {
            breakpoint: { max: 4000, min: 2400},
            items: 8,
            partialVisibilityGutter: 30 // Allow partial visibility of neighboring cards
        },
        medSuperLargeDesktop: {
            breakpoint: { max: 2400, min: 1800},
            items: 6,
            partialVisibilityGutter: 30 // Allow partial visibility of neighboring cards
        },
        superLargeDesktop: {
            breakpoint: { max: 1800, min: 1024},
            items: 4,
            partialVisibilityGutter: 30 // Allow partial visibility of neighboring cards
        },
        desktop: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            partialVisibilityGutter: 20
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 2,
            partialVisibilityGutter: 20
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 10
        }
    };

    return (
        <div id = "projects" data-aos="zoom-in-up" className="project-section">
          <div className="topTitleBox">
          <div className="About-title-box">
            <h1 id="About" className="red-line">Projects</h1>
          </div>   
          </div>   


        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={props.deviceType !== "mobile"}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="transform 0.5s ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            partialVisbile // To ensure partial visibility
        >
            {projects.map((project, index) => (
                <ProjectCard
                    key={index}
                    title={project.title}
                    description={project.description}
                    imageUrl={project.imageUrl}
                    projectType={project.projectType}
                />
            ))}
        </Carousel>
        </div>
    );
};

export default ProjCarousel;

