import React from 'react';
import './Contact.css'; // Ensure to add or adjust your styles here

const Contact = () => {
  const handleCVDownload = () => {
  const link = document.createElement('a');
  link.href = 'BenedictShortCV.pdf'; // Replace with the actual path
  link.download = 'BenedictShortCV.pdf'; // Set the desired filename
  link.click();
};
    return (
        <div id="contact" className="contact-page parallax">
            <div data-aos="zoom-in-up" data-aos-once="true" className="contact-content">
                <div className="container">
                    <div className="row">
                        {/* Photo and Contact Details */}
                        <div className="col-12 col-md-6 photo-container">
                            <img src="/profile_contact.jpg" alt="me" className="contact-photo" />
                        </div>
                        <div className="col-12 col-md-6 contact-info-container">
                            <h1 className="contact-title">Please Contact Me</h1>
                            <div className="contact-details">
                                <div className="contact-item">
                                    <i className="fas fa-phone-alt"></i>
                                    <a href={`tel:+447804909209`} className="contact-link">+44 7804 909209</a>
                                </div>
                                <div className="contact-item">
                                    <i className="fas fa-envelope"></i>
                                    <a href={`mailto:benedictnjshort@gmail.com`} className="contact-link">benedictnjshort@gmail.com</a>
                                </div>
                                <div className="contact-item">
                                    <i className="fas fa-map-marker-alt"></i>
                                    <span className="contact-text">London, UK</span>
                                </div>
                            </div>
                            <div className="social-icons">
                                {/* Add your social media links here */}
                                {/* Example: */}
                                <a title="LinkedIn" rel="noopener noreferrer" target="_blank" href="https://uk.linkedin.com/in/benedict-short-4a51071b6">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                                <a title="GitHub" rel="noopener noreferrer" target="_blank" href="https://github.com/BenShort">
                                    <i className="fab fa-github"></i>
                                </a>

                                  <button class = "cv-button" onClick={handleCVDownload}>Download CV</button>
                                {/* More icons */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;

