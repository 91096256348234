import React from 'react'
import { section2title } from '../../profile'

const About = () => {
    return (
        <div id="about" className="effect2">
          <div className="About-title-box">
            <h1 id="About" className="red-line">{section2title}</h1>
          </div>   
<div class="container">
    <div class="image-text-wrapper">
      <img src="ben_short_headshot.png" alt="Benedict Short" class="responsive-image" />
      <div class="text-content">
<p>Hi, I am a final year Electronic and Information Engineering Student at Imperial College London, graduating in 2025 with an MEng on track for a high First Class. I love building low level applications and developing Signal Processing algorithms, which has led me to specialise in Computer Architecture, Digital Electronics, Computer Networks and Statistical Signal Processing at university.</p>
            <p>
            I am a dual national (British/German) and speak both languages fluently. Earlier this year I moved to Weissach, Germany for six months to work for Porsche Motorsports, but I returned to London in October to complete my studies. Every evening I go running and during term-time I practice kayaking as I plan to go on a trip to the Alps next year. I love Motorsports and most recently went to 24 Hours of Le Mans (where I spent all day on track)!</p>


    <p>I continuously achieve high grades at university and was on the Dean's List for Academic Excellence this year with an average of 82.55%. Some of my best results are in Advanced Computer Architecture (91.6%), Embedded Systems (88.20%), Digital System Design (79%), Statistical Signal Processing and Inference (87.59%) and Discrete Mathematics (86.09%)</p>

      </div>
    </div>
  </div>

          <section class="info-cards-section">
        <div class="info-cards">
            <div class="info-card">
                <i class="fas fa-calendar-alt icon"></i>
                <h2>Degree</h2>
                <p>University: Imperial College London</p>
                <p>Electronic and Information Engineering MEng (Graduation 2025)</p>
                <p>Dean's List 2024, scoring 82.55%</p>
                <p>Current Grade: First Class Honours (78.5% overall average)</p>

            </div>
            <div class="info-card">
                <i class="fas fa-microchip icon"></i>
                <h2>Academic Interests</h2>
                <p>Computer Architecture</p>
                <p>Statistical Signal Processing</p>
                <p>Digital Electronics (ASIC & FPGA)</p>
                <p>Computer Networks</p>
            </div>
            <div class="info-card">
                <i class="fas fa-running icon"></i>
                <h2>Free Time & Sports</h2>
                <p>Running</p>
                <p>Kayaking</p>
                <p>Motorsports</p>
                <p>Driving to races like Le Mans and Top Fuel Drag racing</p>
            </div>
        </div>
        </section>
        </div>
    )
}

export default About
