import React, { useEffect, useState } from "react";
import Header from "./components/section/Header";
import About from "./components/section/About";
import Contact from "./components/section/Contact";
import ProjectCarousel from "./components/section/ProjectCarousel";
import InternshipSection from "./components/section/InternshipSection";
import SkillsSection from "./components/section/SkillsSection";
import AOS from "aos";
import "aos/dist/aos.css";
import { animation } from "./profile";
import DocumentIcon from '@mui/icons-material/Description'; // Example for a CV or document icon

function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);
const handleCVDownload = () => {
  const link = document.createElement('a');
  link.href = 'BenedictShortCV.pdf'; // Replace with the actual path
  link.download = 'BenedictShortCV.pdf'; // Set the desired filename
  link.click();
};

  useEffect(() => {
    AOS.init({
      duration: animation.duration,
      once: animation.once,
      disable: !animation.animate,
    });
    // eslint-disable-next-line
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App">
      <div className="toc-button" onClick={toggleMenu}>
        <span className="hamburger">☰</span>
      </div>
      
      <div id="toc-menu">
      <nav className={`toc ${isMenuOpen ? 'open' : ''}`}>
        <div className="col-12 col-md-6 photo-container">
            
            <img src="/profile.jpeg" alt="me" className="contact-photo-sidebar" />
        </div>
<div className="social-icons">
                                {/* Add your social media links here */}
                                {/* Example: */}
                                <a title="LinkedIn" rel="noopener noreferrer" target="_blank" href="https://uk.linkedin.com/in/benedict-short-4a51071b6">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                                <a title="GitHub" rel="noopener noreferrer" target="_blank" href="https://github.com/BenShort">
                                    <i className="fab fa-github"></i>
                                </a>
<DocumentIcon style={{ cursor: 'pointer' }} onClick={handleCVDownload} id="download-cv-icon"/>
                                {/* More icons */}
                            </div>

        <ul>
            <br/>
          <li><a href="#about" onClick={toggleMenu}>About</a></li>
          <li><a href="#experience" onClick={toggleMenu}>Experience</a></li>
          <li><a href="#skills" onClick={toggleMenu}>Skills</a></li>
          <li><a href="#projects" onClick={toggleMenu}>Projects</a></li>
          <li><a href="#contact" onClick={toggleMenu}>Contact</a></li>
        </ul>
      </nav>
      </div>

      <div className="content">
        <Header id="header" />
        <About id="about" />
        <InternshipSection id="internship" />
        <SkillsSection id="skills" />
        <ProjectCarousel id="projects" />
        <Contact id="contact" />
      </div>
    </div>
  );
}

export default App;
