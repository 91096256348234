import React, { useState, useEffect } from 'react';
import './ScrollArrow.css';

const ScrollArrow = () => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`scroll-arrow-container ${visible ? 'visible' : 'hidden'}`}>
            <div className="scroll-arrow">
                <p>Scroll for more</p>
            </div>
        </div>
    );
};

export default ScrollArrow;

